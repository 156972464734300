@import 'includes';

.panels {
  @include clearfix;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
  &.item-count-1 {
    @include breakpoint('medium') {
      flex-wrap: nowrap;
      @include grid-layout(1, '.panels-item', $global-margin);
    }
    .panels-item {
      max-width: 40em;
    }
  }
  &.item-count-2 {
    @include breakpoint('medium') {
      flex-wrap: nowrap;
      @include grid-layout(2, '.panels-item', $global-margin);
    }
  }
  &.item-count-3 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      @include grid-layout(2, '.panels-item', $global-margin);
      .panels-item:nth-of-type(3n+1) {
        width: 100%;
        .panel-image {
          height: 14em;
        }
      }
    }
    @include breakpoint('large') {
      flex-wrap: nowrap;
      @include grid-layout(3, '.panels-item', $global-margin);
      .panels-item:nth-of-type(3n+1) {
        width: 33.33333%;
        .panel-image {
          height: 12em;
        }
      }
    }
  }
  &.item-count-4,
  &.item-count-8 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      @include grid-layout(2, '.panels-item', $global-margin);
    }
    @include breakpoint('large') {
      flex-wrap: nowrap;
      @include grid-layout(4, '.panels-item', $global-margin);
    }
  }
  &.item-count-5 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      .panels-item:nth-of-type(n+3) {
        -webkit-align-content: center;
      }
    }
  }
  &.item-count-6,
  &.item-count-9 {
    @include breakpoint('medium') {
      flex-wrap: wrap;
      @include grid-layout(2, '.panels-item', $global-margin);
      .panels-item:nth-of-type(3n+1) {
        width: 100%;
        .panel-image {
          height: 14em;
        }
      }
    }
    @include breakpoint('large') {
      @include grid-layout(3, '.panels-item', $global-margin);
      .panels-item:nth-of-type(3n+1) {
        width: 33.33333%;
        margin-bottom:inherit;
        .panel-image {
          height: 12em;
        }
      }
    }
  }
  &-item {
    display: flex;
      @include breakpoint('large') {
        max-width: 25em;
        }
    }
}
